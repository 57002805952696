import { REQUIRED } from "@/util/validation-rules"

export default class RefundValidator {
  constructor(refund, validator) {
    this.refund = refund
    this.validator = validator
  }

  validate () {
    this.validator.resetFields()
    this.validateRequired('amount', this.refund.amount)
    return this.validator.isValid()
  }

  validateRequired(field, data) {
    let match = REQUIRED(data)
    if(match === true) return true
    this.validator.invalidField(field, match)
    return false
  }
}
