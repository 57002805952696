<script>
import Svc from "@/services/payments-service";
import Repo from "@/models/PaymentRepo"
import errorsMixin from '@/mixins/errors.mixin';
import RefundValidator from "@/models/refundValidator"
import FormErrors from '@/util/form-errors';
import showingMixin from "@/mixins/showing.mixin"

export default {
  render() {
    return this.$scopedSlots.default({
      showing: this.showing,
      updateShowing: this.updateShowing,
      formData: this.formData,
      updateFormData: this.updateFormData,
      validateBeforeCreate: this.validateBeforeCreate
    })
  },
  props: {
  },

  mixins: [errorsMixin, showingMixin],


  data: () => ({
    svc: new Svc(
      new Repo()
    ),
    formErrors: new FormErrors(),
    formData: {
      amount: null
    }
  }),

  methods: {
    validateBeforeCreate(ID, data) {
      if(new RefundValidator(data, this.formErrors).validate()) 
        return this.create(ID, data)
      this.$notify({type: 'error', text: 'invalid fields'})
    },

    async create(ID, data) {
      try {
        this.processing = true
        let res = await this.svc.createRefund(ID, data)
        this.$notify({text: 'Refund created', type: 'success'})
        this.$emit('refunded', {ID, data: res})
        this.updateShowing(false)
        this.reset()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    },

    reset() {
      this.formData = {
        amount: null
      }
    },

    updateFormData(field, val) {
      this.formData[field] = val
    }
        
  }
}
</script>